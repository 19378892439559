<template>
    <div class="common-layout">
      <el-container>
        <el-header style="height: 200px;"><el-image src="/img/bg.jpg" style="max-height: 200px;" /></el-header>
        <el-main>
        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="onClickDownloadArd">
              <img alt='icon' style='width: 20px;height: 20px;margin-right: 5px;' src="/img/android.svg" />
              Download Android</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary"><img alt='icon' style='width: 20px;height: 20px;margin-right: 5px;' src="/img/apple.svg" />Download IOS</el-button>
          </el-col>
        </el-row>
          <el-tabs v-model="activeName" class="demo-tabs" style="margin-top: 50px">
            <el-tab-pane label="android Installation Notes" name="ard" style="min-height: 600px;">Installation Notes</el-tab-pane>
            <el-tab-pane label="ios Installation Notes" name="ios" style="min-height: 600px;">Installation Notes</el-tab-pane>
          </el-tabs>
        </el-main>
        <el-footer>Copyright © 2022-2024 Lovewonders All Rights Reserved.</el-footer>
      </el-container>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "MainMenu",
  components: { },
  props: undefined,
  emits: [],
  expose: [],
  setup () {
    const activeName = ref<string>("ard");
    const onClickDownloadArd = () => {
      console.log("onClickDownloadArd");
      window.location.href = "https://lookfant.cc/lovewonders.apk";
    };
    return {
      activeName,
      onClickDownloadArd
    };
  }
});
</script>
